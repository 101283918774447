import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { get, post } from "services/api"


import { useNotification } from 'context/notification';

// @mui material components
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";


import DefaultInfoCard from "components/Cards/InfoCards/DefaultInfoCard";
import DefaultInfoWithSelectCard from "components/Cards/InfoCards/DefaultInfoWithSelectCard";

import MDTypography from "components/MDTypography";
import DynamicTable from "components/Tables";

function Dashboard() {

  const navigate = useNavigate();

  const [dashboardData, setDashboardData] = useState([]);


  const { showNotification } = useNotification();

  const getDashboardDetails = async () => {
    try {
      const response = await get("https://api.dhwani.xyz/secure/dashboard/user", {
        withCredentials: true
      }); // Replace with your backend endpoint
      const dashboardDataResponse = response.data;

      console.log("dashboardData is ", dashboardDataResponse);

      setDashboardData(dashboardDataResponse)
    } catch (error) {
      if (error.response.status === 401) {
        console.log("user is unauthorized here")

        showNotification("error", "Unable to authenticate. Please try logging in again")
        navigate('/authentication/sign-in');
      }
      console.error("Error fetching dashboard:", error);
    }
  };

  useEffect(() => {
    getDashboardDetails();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} xl={3}>
                {dashboardData.words_left != null ?
                <DefaultInfoCard
                  icon="account_balance"
                  title="Standard Words Left"
                  description="Number of words available on standard engine"
                  value={dashboardData.words_left.standard}
                /> : <></>
                }
              </Grid>
              <Grid item xs={12} md={3} xl={3}>

              {dashboardData.words_left != null ?
                <DefaultInfoWithSelectCard
                  icon="paypal"
                  title="Engine <> Words Left"
                  keyValueStore={dashboardData.words_left}
                /> : <></>
              }
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
              {dashboardData.subscriptions != null ?  <DefaultInfoCard
                  icon="account_balance"
                  title="Subscriptions"
                  description="Number of subscriptions"
                  value={dashboardData.subscriptions.length.toString()}
                /> : <></> }
              </Grid>
              <Grid item xs={12} md={3} xl={3}>
              {dashboardData.executions != null ? <DefaultInfoCard
                  icon="paypal"
                  title="Executions"
                  description="Number of executions"
                  value={dashboardData.executions.length.toString()}
                /> : <></>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
        {dashboardData.executions != null ?
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Executions
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DynamicTable data={dashboardData.executions} source="executions" />
              </MDBox>
            </Card>
          </Grid> : <></>
          }
          {dashboardData.subscriptions != null ?
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Subscriptions
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DynamicTable data={dashboardData.subscriptions} source="subscriptions" />
                </MDBox>
              </Card>
            </Grid> : <></>
          }
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
