import React, { useEffect, useState } from "react";
import axios from "axios";

import MDBox from "components/MDBox";

import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import { Container, Grid, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import {
  faGift,
  faBicycle,
  faMotorcycle,
  faCar,
  faRocket,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

// Define your color options
const colorOptions = {
  Free: {
    lightColor:
      "linear-gradient(135deg, rgba(169, 169, 169, 0.8) 0%, rgba(105, 105, 105, 0.8) 100%)",
    darkColor: "linear-gradient(135deg, rgba(169, 169, 169, 1) 0%, rgba(105, 105, 105, 1) 100%)",
  },
  Daily: {
    lightColor: "linear-gradient(135deg, rgba(248, 86, 131, 0.8) 0%, rgba(154, 91, 211, 0.8) 100%)",
    darkColor: "linear-gradient(135deg, rgba(248, 86, 131, 1) 0%, rgba(154, 91, 211, 1) 100%)",
  },
  Weekly: {
    lightColor: "linear-gradient(135deg, rgba(255, 176, 66, 0.8) 0%, rgba(243, 33, 215, 0.8) 100%)",
    darkColor: "linear-gradient(135deg, rgba(255, 176, 66, 1) 0%, rgba(243, 33, 215, 1) 100%)",
  },
  Monthly: {
    lightColor: "linear-gradient(135deg, rgba(36, 255, 114, 0.8) 0%, rgba(91, 130, 255, 0.8) 100%)",
    darkColor: "linear-gradient(135deg, rgba(36, 255, 114, 1) 0%, rgba(91, 130, 255, 1) 100%)",
  },
  "Monthly Plus": {
    lightColor: "linear-gradient(135deg, rgba(0, 102, 204, 0.8) 0%, rgba(153, 51, 255, 0.8) 100%)",
    darkColor: "linear-gradient(135deg, rgba(0, 102, 204, 1) 0%, rgba(153, 51, 255, 1) 100%)",
  },
};

// Define your icon options
const icons = {
  Free: faGift,
  Daily: faBicycle,
  Weekly: faMotorcycle,
  Monthly: faCar,
  "Monthly Plus": faRocket,
};

const PricingCard = styled(Box)(({ theme, darkMode, color }) => ({
  position: "relative",
  height: "100%",
  justifyContent: "space-between",
  background: darkMode ? color.darkColor : color.lightColor,
  borderRadius: "15px",
  margin: "0 auto",
  padding: "40px 20px",
  boxShadow: "0 10px 15px rgba(0,0,0,.1)",
  //textAlign: 'center',
  transition: "0.5s",
  overflow: "hidden",
  "&:hover": {
    transform: "scale(1.01)",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "40%",
    background: "rgba(255, 255, 255, .1)",
    zIndex: 1,
    transform: "skewY(-5deg) scale(1.5)",
  },
}));

const PricingIcon = styled(Box)(({ color }) => ({
  color: "#fff",
  fontSize: "60px",
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  textAlign: "center",
  lineHeight: "100px",
  boxShadow: "0 10px 10px rgba(0,0,0,.1)",
  margin: "0 auto 20px",
  background: color,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const PricingPage = ({ darkMode }) => {
  const [plans, setPlans] = useState([]);

  const [selectedRegion, setSelectedRegion] = useState('india');

  const handleChange = (event) => {
    setSelectedRegion(event.target.value);
    fetchPlans(event.target.value)
  };

  const fetchPlans = async (selectedRegionInParams) => {
    try {
      const response = await axios.get(`https://api.dhwani.xyz/pricing/active/${selectedRegionInParams}`); // Replace with your backend endpoint
      const fetchedPlans = response.data;

      // Map through the fetched plans and assign colors and icons
      const mappedPlans = fetchedPlans.map((plan) => ({
        ...plan,
        icon: icons[plan.name],
        ...colorOptions[plan.name],
        details: plan.details.split("|").map((detail) => detail.trim()),
      }));

      const order = ["Free", "Daily", "Weekly", "Monthly", "Monthly Plus"];
      const sortedPlans = mappedPlans.sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));

      // Set the state with the mapped and sorted plans
      setPlans(sortedPlans);
    } catch (error) {
      console.error("Error fetching pricing plans:", error);
    }
  };

  useEffect(() => {
    fetchPlans("india");
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        textAlign="center"
        color={darkMode ? "#fff" : "#000"}
      >
        Pricing Plans
      </Typography>

      <MDBox style={{ backgroundColor: "#fff", padding: "20px" }}>
      <MDBox mt={2} mb={2} >

      <Typography
                        variant="h4"
                        style={{ paddingTop: "10px", paddingBottom: "10px", textAlgign: "left" }}
                      >
                        Please Select A region
                      </Typography>
        <FormControl fullWidth>
          <InputLabel shrink={selectedRegion ? true : undefined} id="select-label">Select a region</InputLabel>
          <Select
            
            labelId="select-label"
            id="select"
            value={selectedRegion}
            label="Select an option"
            onChange={handleChange}
            style={{ paddingTop: '8px', paddingBottom: '8px' }}
          >
              <MenuItem key="india" value="india">India</MenuItem>
              <MenuItem key="international" value="international">International</MenuItem>
          </Select>
        </FormControl>
        </MDBox>
        </MDBox>
      <Grid container spacing={2} style={{ justifyContent: "center" }}>
        {/* <Grid width={300}></Grid> */}
        {plans.map((plan) => (
          <Grid width={300} item xs={12} sm={6} md={4} lg={true} key={plan.name}>
            <PricingCard color={plan} darkMode={darkMode}>
              <PricingIcon color={darkMode ? plan.darkColor : plan.lightColor}>
                <FontAwesomeIcon icon={plan.icon} />
              </PricingIcon>
              <Typography variant="h5" color="#fff" zIndex={2} style={{ textAlign: "center" }}>
                {plan.name}
              </Typography>
              {plan.name != "Free" ? (
                <Typography
                  variant="h3"
                  component="p"
                  color="#fff"
                  zIndex={2}
                  gutterBottom
                  style={{ textAlign: "center" }}
                >
                  {plan.price} {plan.region == "india" ? "INR" : "USD"}
                </Typography>
              ) : (
                <br></br>
              )}
              <Box my={2} zIndex={2}>
                {plan.details.map((detail, i) => (
                  <Grid container key={i} spacing={0}>
                    <Grid item xs={2} md={2} lg={2} style={{ paddingTop: "15px" }}>
                      <FontAwesomeIcon icon={faCheck} />
                    </Grid>
                    <Grid item xs={10} md={10} lg={10}>
                      <Typography
                        variant="body1"
                        color="#fff"
                        style={{ paddingTop: "10px", paddingBottom: "10px", textAlgign: "left" }}
                      >
                        {detail}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Box>
              {plan.name != "Free" ? (
                <MDButton

                  component="a"
                  href="#"
                  color="dark"
                  variant="gradient"
                  fullWidth
                  sx={{
                    margin: "20px auto 0",
                    fontSize: "16px",
                    boxShadow: "0 5px 10px rgba(0, 0, 0, .1)",
                    borderRadius: "40px",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "none",
                    },
                    position: "absolute",
                    width: "auto",
                    bottom: "10px",
                    // paddingBottom: "10px"
                  }}
                >
                  Order Now
                </MDButton>
              ) : (
                <></>
              )}
            </PricingCard>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

PricingPage.propTypes = {
  darkMode: PropTypes.bool.isRequired
};

export default PricingPage;
