import React, { useRef, useState, useEffect } from 'react';

import PropTypes from "prop-types";
import { Modal, Box, Button, Typography, LinearProgress, useTheme, IconButton } from '@mui/material';
import CustomProgressBar from 'components/ProgressBar'
import CloseIcon from '@mui/icons-material/Close';

const SynthesisModal = ({ isOpen, handleClose, audioUrl, darkMode }) => {
  const modalAudioRef = useRef(null);
  const theme = useTheme();

  // State to manage progress and duration
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  // Play the audio
  const handlePlay = () => {
    if (modalAudioRef.current) {
      if (isPlaying) {
        modalAudioRef.current.pause(); // Pause the audio
      } else {
        modalAudioRef.current.play();  // Play the audio
      }
      setIsPlaying(!isPlaying); // Toggle play/pause state
    }
  };

  // Download audio
  const handleDownload = async () => {
    try {
      const response = await fetch(audioUrl);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', 'synthesized_speech.mp3');
      document.body.appendChild(link);
      link.click();
  
      // Clean up the URL object
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Download failed', error);
    }
  };

  // Set duration when metadata is loaded
  const handleLoadedMetadata = () => {
    if (modalAudioRef.current) {
      setDuration(modalAudioRef.current.duration); // Set the duration of the audio
    }
  };

  // Handle time update using setInterval to simulate continuous progress update
  useEffect(() => {
    const timer = setInterval(() => {
      if (modalAudioRef.current && modalAudioRef.current.currentTime && duration > 0) {
        setProgress((modalAudioRef.current.currentTime / duration) * 100); // Calculate progress based on currentTime and duration
      }
    }, 500); // Update every 500ms

    // Clear timer on component unmount
    return () => {
      clearInterval(timer);
    };
  }, [duration]);

  return (
    <Modal open={isOpen} onClose={handleClose} aria-labelledby="synthesis-modal-title">

      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: darkMode ? '#222' : '#fff',
        color: darkMode ? '#f1f1f1' : '#000',
        borderRadius: '12px',
        boxShadow: darkMode
          ? '0px 10px 30px rgba(0, 0, 0, 0.5)'
          : '0px 10px 30px rgba(0, 0, 0, 0.2)',
        padding: theme.spacing(4),
        outline: 'none',
      }}>
                <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
            color: darkMode ? '#fff' : '#000',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="synthesis-modal-title" variant="h6" component="h2" sx={{ fontWeight: 700, color: darkMode ? '#ffffff' : '#000000' }}>
          Speech Synthesis Completed!
        </Typography>
        <Typography sx={{ mt: 2, color: darkMode ? '#bbbbbb' : '#555555', lineHeight: 1.6 }}>
          You can listen to the synthesized speech below. It will also be available in your dashboard for one month before it is deleted.
        </Typography>
        {audioUrl && (
          <>
            <audio
              ref={modalAudioRef}
              src={audioUrl}
              style={{ display: 'none' }}
              onLoadedMetadata={handleLoadedMetadata} // Set duration once metadata is loaded
            />
            <Box sx={{ mt: 2 }}>
              <CustomProgressBar progress={progress} /> {/* Pass the progress value */}
              <Typography variant="body2" color="textSecondary" align="right">
                {`${Math.floor((progress / 100) * duration)} / ${Math.floor(duration)} sec`}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: theme.spacing(3) }}>
              <Button variant="contained" sx={{
                backgroundColor: darkMode ? '#007BFF' : '#007BFF',
                color: '#fff',
                padding: '8px 16px',
                borderRadius: '8px',
                border: 'none',
                cursor: 'pointer',
                fontSize: '14px',
                fontWeight: 'bold',
                transition: 'background-color 0.3s ease',
              }} onClick={handlePlay}>
                {isPlaying ? 'Pause' : 'Play'}
              </Button>
              <Button variant="contained" sx={{
                backgroundColor: darkMode ? '#007BFF' : '#007BFF',
                color: '#fff',
                padding: '8px 16px',
                borderRadius: '8px',
                border: 'none',
                cursor: 'pointer',
                fontSize: '14px',
                fontWeight: 'bold',
                transition: 'background-color 0.3s ease',
              }} onClick={handleDownload}>
                Download
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

// PropTypes validation
SynthesisModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  audioUrl: PropTypes.string.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

export default SynthesisModal;
