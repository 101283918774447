import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Grid,
  TextField,
  Modal,
  Button,
  Select,
  MenuItem,
  Avatar,
  Typography,
  Box,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";

import { get, post } from "services/api";
import { useNavigate } from "react-router-dom";
import { useNotification } from "context/notification";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Footer from "components/Footer";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import SynthesisModal from "./synthesisModal";

import { useMaterialUIController } from "context";
import AsyncSynthesisModal from "./asyncSynthesisModal";


const CoreSpeechLayout = () => {

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const audioRef = useRef(null);

  const [selectedEngine, setSelectedEngine] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [selectedVoice, setSelectedVoice] = useState({});
  const [textValue, setTextValue] = useState("");
  const [engineData, setEngineData] = useState([]);
  const [voiceData, setVoiceData] = useState([]);
  const [langaugeEngineMapping, setLangaugeEngineMapping] = useState([]);
  const [fileName, setFileName] = useState(null);


  const [isModalOpen, setModalOpen] = useState(false);
  const [isAsyncModalOpen, setIsAsyncModalOpen] = useState(false);
  const [modalAudioUrl, setModalAudioUrl] = useState('');

  const [uploadedFileId, setUploadedFileId] = useState("");
  const [selectedEngineId, setSelectedEngineId] = useState("");
  const [selectedVoiceId, setSelectedVoiceId] = useState("");
  const [selectedLanguageId, setSelectedLanguageId] = useState({});

  const { showNotification } = useNotification();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const UploadButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
  }));

  const inputFileRef = useRef(null);


  const handleSpeechSynthesisComplete = (url) => {
    setModalAudioUrl(url);
    setModalOpen(true); // Open modal when synthesis is done
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };


  const handleAsyncModalClose = () => {
    setIsAsyncModalOpen(false);
  };

  const handleAsyncSpeechSynthesisComplete = () => {
    setIsAsyncModalOpen(true); // Open modal when synthesis is done
  };


  const getLanguageEngineMapping = async () => {
    try {
      const response = await get("https://api.dhwani.xyz/secure/languages/voices/mapping", {
        withCredentials: true,
      });
      const languageEngineMappingResponse = response.data;

      console.log("languageEngineMappingResponse is ", languageEngineMappingResponse);

      setLangaugeEngineMapping(languageEngineMappingResponse);
    } catch (error) {
      console.error("Error fetching dashboard:", error);
    }
  };

  const getVoicesFromLanguageAndEngine = async (languageCode, engineId) => {
    try {
      const response = await get(
        `https://api.dhwani.xyz/secure/voices/language/${languageCode}/engine/${engineId}`,
        {
          withCredentials: true,
        }
      );
      const voiceData = response.data;

      setVoiceData(voiceData);
    } catch (error) {
      console.error("Error fetching dashboard:", error);
    }
  };

  useEffect(() => {
    getLanguageEngineMapping();
  }, []);


  const AvatarContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    marginTop: theme.spacing(2),
  }));

  const handleFileUpload = async (event) => {
    console.log("event is ", event);
    const file = event.target.files[0];
    if (file) {
      // Handle file upload logic
      console.log(file);
      setFileName(file.name); 

      const formData = new FormData();
      formData.append("file", file);
      // formData.append("user_id", "h2wRWUSdjcLCgr");

      try {
        // Send the file to the backend using axios
        const response = await post(
          "https://api.dhwani.xyz/secure/executionfileupload/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true,
          }
        );

        if (response.status === 200) {
          // Handle successful upload
          setUploadedFileId(response.data.id);
          
          showNotification("success", "Successfully uploaded file");
        } else {
          // Handle unexpected response status
          console.error("File upload failed with status:", response.status);
          showNotification("error", "Some error occured in file upload");
        }
      } catch (error) {
        if (error.response.status === 401) {

          showNotification("error", "Unable to authenticate. Please try logging in again");
          navigate("/authentication/sign-in");
        }

        showNotification("error", "Some error occurred in file upload");
      }
    }
  };

  const initiateSpeechSynthesis = async () => {
    console.log("triggered speech synthesis");

    if (selectedVoiceId === "" || selectedEngineId === "" || selectedLanguageId === "") {
      // Please make sure you have selected voices, engine and language

      showNotification("error", "Please make sure you have selected voices, engine and language");
      return;
    }

    if (textValue !== "" && uploadedFileId !== "") {
      // Either use text box or upload file, not both

      showNotification("error", "Either use text box or upload file, not both");
      return;
    }

    if (textValue !== "" && textValue.length > 5010) {
      showNotification("error", "max limit is 5000 characters currently");
      return;
    }

    setLoading(true); // Start loading

    try {
      let requestBody = {};
      let url = "";

      if (textValue !== "" && uploadedFileId === "") {
        requestBody = {
          script: textValue,
          engine_name: selectedEngine.engine_name.toLowerCase(),
          voice_id: selectedVoice.service_voice_id,
          language_code: selectedVoice.language_code,
          voice_repo_id: selectedVoice.id,
        };

        url = "https://api.dhwani.xyz/secure/synthesize";
      } else if (uploadedFileId !== "" && textValue === "") {
        requestBody = {
          script: "",
          engine_name: selectedEngine.engine_name.toLowerCase(),
          voice_id: selectedVoice.service_voice_id,
          language_code: selectedVoice.language_code,
          voice_repo_id: selectedVoice.id,
          async_execution_id: uploadedFileId,
        };

        url = "https://api.dhwani.xyz/secure/synthesize/large";
      } else {
        // Something is wrong with the selected options. Please refresh and try again

        showNotification("error", "Issue with selected options, Plese refresh and try again")
        return;
      }

      console.log("requestBody is ", requestBody, url);

      const response = await post(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      console.log("Response:", response.data);

      setLoading(false)

      if(!requestBody.async_execution_id || requestBody.async_execution_id === "") {
        handleSpeechSynthesisComplete(response.data.mp3_url);
      }
      

      if(requestBody.async_execution_id && requestBody.async_execution_id !== "") {
        handleAsyncSpeechSynthesisComplete()
      }
    } catch (error) {
      console.log("error is ")
      console.log(error)
      setLoading(false);

      if (error.response.status === 401) {
        console.log("user is unauthorized here");

        showNotification("error", "Unable to authenticate. Please try logging in again");
        navigate("/authentication/sign-in");
      }

      if(error.response) {
        showNotification("error", error.response.data)
      }
    }
  };

  const handleButtonClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    console.log("selectedLanguage val is ", selectedLanguage, event.target.value);
    setSelectedLanguageId(event.target.value.id);

    setEngineData(event.target.value.engines);
  };

  const handleEngineChange = (event) => {
    setSelectedEngine(event.target.value);
    setSelectedEngineId(event.target.value.id);

    if (selectedLanguage != "" && selectedEngine != "") {
      getVoicesFromLanguageAndEngine(selectedLanguage.language_code, event.target.value.engine_id);
    }
  };

  const handleAvatarClick = async (voice) => {
    // If clicked on the same voice and it's playing, stop the audio
    if (selectedVoiceId === voice.id && audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset the audio
      setSelectedVoiceId(null); // Deselect the voice
      return;
    }

    setSelectedVoice(voice);
    setSelectedVoiceId(voice.id);

    const audio = voice.demo_voice_url;

    if (!audio) {
      // no audio present to play
      showNotification("warning", "No demo present!!!");
      return;
    }

    // Helper function to pause the current audio and wait for it to finish pausing
    const pauseCurrentAudio = async () => {
      if (audioRef.current) {
        await audioRef.current.pause(); // Pause the audio
        audioRef.current.currentTime = 0; // Reset the current audio
      }
    };

    // Wait for the current audio to pause before playing the new one
    await pauseCurrentAudio();

    // Create a new audio element and play it
    audioRef.current = new Audio(audio);
    audioRef.current.play();
  };

  const updateText = (event) => {
    setTextValue(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              placeholder="Enter text less than 5000 characters"
              label="Enter Text"
              variant="outlined"
              fullWidth
              multiline
              rows={10}
              inputProps={{ maxLength: 1500 }}
              value={textValue}
              onChange={updateText}
            />

            <MDButton color="primary" variant="contained" onClick={handleButtonClick}>
              Upload File
            </MDButton>
            <input
              type="file"
              ref={inputFileRef}
              style={{ display: "none" }}
              onChange={handleFileUpload}
              accept=".txt"
            />
            {fileName && (
        <MDTypography variant="body2" mt={2}>
          Selected File: {fileName}
        </MDTypography>
      )}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <InputLabel id="select-label">Select a Language</InputLabel>
                <Select
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  id="select"
                  label="Select a language"
                  labelId="select-label"
                  displayEmpty
                  fullWidth
                  style={{ paddingTop: "8px", paddingBottom: "8px" }}
                >
                  {langaugeEngineMapping.map((languageEngineData) => (
                    <MenuItem key={languageEngineData.language_name} value={languageEngineData}>
                      {languageEngineData.language_name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <br></br>

              <Grid item xs={12} md={12} lg={12}>
                <InputLabel id="select-label-engine">Select an Engine</InputLabel>
                <Select
                  value={selectedEngine}
                  onChange={handleEngineChange}
                  id="select"
                  label="Select an engine"
                  labelId="select-label-engine"
                  displayEmpty
                  fullWidth
                  style={{ paddingTop: "8px", paddingBottom: "8px" }}
                >
                  {engineData.map((engine) => (
                    <MenuItem key={engine.engine_name} value={engine}>
                      {engine.engine_name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            <AvatarContainer>
              {voiceData.map((avatar) => (
                <MDBox
                  key={avatar.name}
                  onClick={() => handleAvatarClick(avatar)}
                  style={{
                    cursor: "pointer",
                    textAlign: "center",
                    margin: "8px",
                  }}
                >
                  <Avatar
                    src={avatar.imageUrl}
                    alt={avatar.name}
                    sx={{
                      bgcolor: selectedVoiceId === avatar.id ? "primary.main" : "grey.300", // Change background color
                      color: selectedVoiceId === avatar.id ? "white" : "black", // Change icon/text color
                    }}
                  />
                  <Typography>{avatar.name}</Typography>
                </MDBox>
              ))}
            </AvatarContainer>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <MDBox p={2} mt="auto">
              <MDButton
                component="button"
                onClick={initiateSpeechSynthesis}
                variant="gradient"
                color="info"
                disabled={loading}
                fullWidth
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Synthesize Speech"}
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <SynthesisModal isOpen={isModalOpen} handleClose={handleModalClose} audioUrl={modalAudioUrl} darkMode={darkMode} />
        <AsyncSynthesisModal isOpen={isAsyncModalOpen} handleClose={handleAsyncModalClose} darkMode={darkMode} />
      </Container>

      <div style={{paddingTop: "300px"}}>
      </div>
      <Footer />
    </DashboardLayout>
  );
};

export default CoreSpeechLayout;
