// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import React, { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function DefaultInfoWithSelectCard({ color, icon, title, keyValueStore }) {

    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

  return (
    <Card>
      <MDBox p={2} mx={3} display="flex" justifyContent="center">
        <MDBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor={color}
          color="white"
          width="4rem"
          height="4rem"
          shadow="md"
          borderRadius="lg"
          variant="gradient"
        >
          <Icon fontSize="default">{icon}</Icon>
        </MDBox>
      </MDBox>
      <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
        
        <MDBox mt={2} mb={2}>
        <FormControl fullWidth>
          <InputLabel shrink={selectedValue ? true : undefined} id="select-label">Select an option</InputLabel>
          <Select
            
            labelId="select-label"
            id="select"
            value={selectedValue}
            label="Select an option"
            onChange={handleChange}
            style={{ paddingTop: '8px', paddingBottom: '8px' }}
          >
            {Object.entries(keyValueStore).map(([key, value]) => (
              <MenuItem key={value} value={value}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </MDBox>
        {selectedValue && (
          <MDTypography variant="h5" fontWeight="medium">
            {selectedValue}
          </MDTypography>
        )}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
DefaultInfoWithSelectCard.defaultProps = {
  color: "info",
  icon: "",
  title: "",
  keyValueStore: {}
};

// Typechecking props for the DefaultInfoCard
DefaultInfoWithSelectCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  keyValueStore: PropTypes.object.isRequired
};

export default DefaultInfoWithSelectCard;
