import { useState, useEffect } from "react";

import { GoogleOAuthProvider } from "@react-oauth/google";

import { useNavigate } from 'react-router-dom';
import './sign-in.css';
// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";


// import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";

import { useNotification } from 'context/notification';

import { get, post } from "services/api"

const Login = () => {

  useEffect(
    () => {
      logout()
    },
    []
  )


  const navigate = useNavigate();

  const { showNotification } = useNotification();

  const handleLoginSuccess = async (response) => {

    try {
      const idToken = response.credential;

      console.log("response is ", response);

      let url = "https://api.dhwani.xyz/login"
      let requestBody = {
        "credential" : idToken
      }

      logout()
      const responseData = await post(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true
      });

      showNotification("success", "Successfully logged in!!!")
      navigate('/dashboard');

      console.log("responseData ", responseData)
  }
  catch(error) {
    console.log("error occurred", error)
  }
  };

  const logout = async () => {
    console.log("initiate logout")

    let url = "https://api.dhwani.xyz/logout"

    const responseData = await post(url, {}, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true
    });
  }

  const handleLoginFailure = (error) => {
    console.error("Login failed:", error);
  };

  return (
    <div className="sign-in-page">
      <div className="left-container"></div>
      <div className="right-container">
        <div className="right-container__box">
          <div className="right-container-box">
            <h2 className="right-container__h2">Nice to see you!</h2>
            <p className="right-container__p">Use your google auth to sign in</p>
          </div>
           <GoogleOAuthProvider clientId="275828416055-geun2553q93e5j8727r3h89tmjo5hfo6.apps.googleusercontent.com">
           <Grid container spacing={0} justifyContent="center" sx={{ mt: 1, mb: 2 }} style={{}}>
                  <GoogleLogin onSuccess={handleLoginSuccess} onError={handleLoginFailure} logo_alignment="left" theme="filled_blue"  size="large" width="800" />

             
             </Grid>
           </GoogleOAuthProvider>
        </div>
      </div>
    </div>
  );


};

export default Login;
